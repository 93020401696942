import { format } from "date-fns";
import idLocale from "date-fns/locale/id";

export default function useDateFormatter(): any {
  const formatDateTime = (date: string, formatStr = "D MMMM YYYY, HH:mm") =>
    date ? format(new Date(date), formatStr, { locale: idLocale }) : "";

  return {
    formatDateTime,
  };
}
